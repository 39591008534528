import Vue from 'vue'
import { Button,ButtonGroup,Form,FormItem,Input,Select,Option,DatePicker,TimePicker,Col,Checkbox,
        CheckboxButton,CheckboxGroup,Radio,RadioButton,RadioGroup,Switch,Message,MessageBox,
        Aside,Container,Header,Main,Footer,Menu,MenuItem,MenuItemGroup,Submenu,Table,TableColumn,
        Icon,Row,Notification,Dialog,Pagination,Tag,Upload,Loading,Popover,Alert,Card,Autocomplete
} from 'element-ui'

Vue.use(Button);
Vue.use(ButtonGroup);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Select);
Vue.use(Option);
Vue.use(DatePicker);
Vue.use(TimePicker);
Vue.use(Col);
Vue.use(Checkbox);
Vue.use(CheckboxButton);
Vue.use(CheckboxGroup);
Vue.use(Radio);
Vue.use(RadioButton);
Vue.use(RadioGroup);
Vue.use(Switch);
Vue.use(Aside);
Vue.use(Container);
Vue.use(Header);
Vue.use(Main);
Vue.use(Footer);
Vue.use(Menu);
Vue.use(MenuItem);
Vue.use(MenuItemGroup);
Vue.use(Submenu);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Icon);
Vue.use(Row);
Vue.use(Dialog);
Vue.use(Pagination);
Vue.use(Tag);
Vue.use(Upload);
Vue.use(Loading);
Vue.use(Popover);
Vue.use(Alert);
Vue.use(Card);
Vue.use(Autocomplete);


Vue.prototype.$msg = Message;
Vue.prototype.$msgBox = MessageBox;
Vue.prototype.$msgNot = Notification;
