<template>
  <el-row style="margin-bottom: 10px;margin-top: -10px">
    <el-card class="box-card" style="margin-bottom: 5px">
        <span >
          <el-button type="primary" @click="showDlgAdd(null)">添加群链接</el-button>
          <el-button type="danger" @click="postClearData">清空所有</el-button>
        </span>

    </el-card>
    <el-col style="margin-top: -5px">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="this.pagParams.page"
          :page-sizes="[20, 100, 200, 500]"
          :page-size="this.pagParams.limit"
          :total="this.pagParams.total"
          layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </el-col>
    <el-table
        class="el-ta"
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        @selection-change="handleSelectionChange"
        stripe
        border
        size="mini"
        highlight-current-row
        highlight-selection-row
    >

      <el-table-column
          type="selection"
          width="40px">
      </el-table-column>
<!--      <el-table-column-->
<!--          prop="sort"-->
<!--          label="序号"-->
<!--          width="80px"-->
<!--          sortable-->
<!--          show-overflow-tooltip>-->
<!--        <template slot-scope="scope">{{ scope.$index+1 }}</template>-->
<!--      </el-table-column>-->
      <el-table-column
          prop="lab"
          label="群编号"
          width="100px"
          sortable
          show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
          prop="url"
          label="群链接"
          width="300px"
          show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
          prop="state"
          label="状态"
          width="100px"
          sortable
          :filters="[{ text: '停用', value: 0 }, { text: '启用', value: 1 }]"
          :filter-method="filterStatus"
          filter-placement="bottom-end"
      >
        <template slot-scope="scope">
          <el-tag
              :type="scope.row.state === 0 ? 'danger' : 'success'"
              disable-transitions>{{scope.row.state === 1 ? '启用' : '停用'}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
          prop="count"
          label="群人数"
          width="100px"
          sortable
          show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
          prop="limit"
          label="限制人数"
          width="100px"
          sortable
          show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
          prop="other"
          label="群名/备注"
          width="150px"
          sortable
          show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
          prop="work"
          label="操作">
        <template slot-scope="scope">
          <el-button icon="el-icon-edit" size="mini" @click="showDlgAdd(scope.row)">编辑</el-button>
          <el-button type="warning" icon="el-icon-error" size="mini" @click="postDelData(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>


    <el-dialog
        :visible.sync="visChange"
        width="30%"
        title="编辑"
    >
      <el-form ref="form2" :model="addForm" label-width="80px">
        <el-form-item label="群编号">
          <el-input v-model="addForm.lab" style="width: 80%"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="postSetData()">确 定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog
        :visible.sync="visAdd"
        width="50%"
        title="添加群链接"
    >
      <el-form ref="form" :model="addForm" label-width="80px">
        <el-form-item label="链接">
          <el-input type="textarea" :rows="10" v-model="addForm.url" style="width: 90%"></el-input>
        </el-form-item>
        <el-row>
          <el-col :span="8">
            <el-form-item label="编号">
              <el-input v-model="addForm.lab" style="width: 80%"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="限制">
              <el-input v-model="addForm.lab" style="width: 80%"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8" >
            <el-form-item label="备注">
              <el-input v-model="addForm.other" style="width: 80%"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="状态">
          <el-radio v-model="addForm.state" :label="0" border>停用</el-radio>
          <el-radio v-model="addForm.state" :label="1" border>启用</el-radio>
        </el-form-item>
        <el-form-item>
          <!--          <el-button @click="dialogVisible = false">取 消</el-button>-->
          <el-button type="primary" @click="postData">确 定</el-button>
        </el-form-item>
      </el-form>

    </el-dialog>
  </el-row>
</template>

<script>

import { Base64 } from "js-base64";
import { timeago } from '@/utils/formatTime'

export default {
  data(){
    return {
      userName : window.sessionStorage.getItem('account'),
      intervalId: null,
      tmp:'',
      tableData: [],
      pagParams:{
        total:20,limit:20,page:1,searchval:''
      },

      addForm:{
        url:'',
        lab:'1',
        state:1,
        other:'',
        count:0,
        limit:0,
      },
      isSave:false,
      rules: {
        name: [
          { type: 'Number',required: true, message: '请输入', trigger: 'blur'},
          {min: 1, max: 3, message: '长度在 1 到 3 个字符', trigger: 'blur'}
        ],
      },

      visChange:false,
      visAdd:false,

    }
  },
  created() {
    this.getList();
    this.intervalId = setInterval(this.getList, 3000);
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  },
  methods:{
    filterStatus(value, row) {
      return row.state === value;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pagParams.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.pagParams.page = val;
      this.getList();
    },
    refreshData(){
      this.pagParams.page  = 1;
      // this.pagParams.limit = 5;
      // this.pagParams.total = 5;
      this.pagParams.searchval = '';
      this.getList();
    },
    showDlgSet(row){
      this.visChange = !this.visChange;
      if (this.visChange){
        this.addForm = row;
      }

    },
    showDlgAdd(row){
      this.visAdd = !this.visAdd;
      if (this.visAdd){
        if (row){
          this.addForm = row;
          this.isSave  = true;
        }else {
          this.addForm.url = '';
          this.addForm.lab = '1';
          this.addForm.state = 1;
          this.addForm.other = '';
          this.isSave  = false;
        }
      }

    },

    async postDelData(row){
      if (!this.userName) {
        this.$msgNot.error('登录状态异常,请重新登录');
        return;
      }
      await this.$msgBox.confirm('确认删除？','提示',{confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning',})
          .then(async ()=>{
            const {data:res} = await this.$ajax.post("/api/group/delData",{'account':this.userName,'data':row});
            if (res.code === 200){
              this.czVisible = false;
              this.$msgNot.success(res.msg);
              await this.getList();
            }else {
              this.$msgNot.error(res.msg);
            }

          }).catch(()=>{

          });

    },

    async postClearData(){
      if (!this.userName) {
        this.$msgNot.error('登录状态异常,请重新登录');
        return;
      }

      await this.$msgBox.confirm('确认清空所有数据？','提示',{confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning',})
          .then(async ()=>{
            const {data:res} = await this.$ajax.post("/api/group/clearData",{'account':this.userName});
            if (res.code === 200){
              this.$msgNot.success('清空成功');
              await this.getList();
            }else {
              this.$msgNot.err('清空失败:'+res.msg);
            }

          }).catch(()=>{

          });
    },

    async postData() {
      if (!this.userName) {
        this.$msgNot.error('登录状态异常,请重新登录');
        return;
      }
      // console.log(this.addForm);
      await this.$msgBox.confirm('确认添加链接？','提示',{confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning',})
          .then(async ()=>{
            const {data: res} = await this.$ajax.post("/api/group/addData", {'account': this.userName, 'save':this.isSave,'data':this.addForm});
            if (res.code === 200) {
              this.$msgNot.success(res.msg);
              this.visAdd = false;
              await this.getList();
            } else {
              this.$msgNot.error(res.msg);
            }

          }).catch(()=>{

          });
    },
    async getList(){
      if (!this.userName) {
        this.$msgNot.error('登录状态异常,请重新登录');
        return;
      }

      const {data:res} = await this.$ajax.post("/api/group/getList",{'account':this.userName,'data':this.pagParams});
      if (res.code === 200){
        this.tableData = res.data;
        this.pagParams.total = res.total;

      }else {
        this.$msgNot.error('失败:'+res.msg);
      }

    },
    formatDate(row, column) {
      if(!row[column.property]) return '';

      let date = new Date(row[column.property] * 1000)
      const Y = date.getFullYear() + '-'
      const M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-'
      const D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' '
      const h = date.getHours() < 10 ? '0' + date.getHours() + ':' : date.getHours() + ':'
      const m = date.getMinutes() < 10 ? '0' + date.getMinutes() + ':' : date.getMinutes() + ':'
      const s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      return Y + M + D + h + m + s
    },
    calculateDays(caTime) {
      let t = (caTime - (Date.now() /1000)) / 86400;
      let s = Math.floor(t+1)+'天';
      if (t < 0) s ="过期"
      return s;
    },
    timeAgo(val) {
      return timeago(val)
    },

  },
}
</script>

<style scoped>
.el-ta {
  /*background-color: #222D32;*/
  color: #000000;
  text-align: left;
  /*line-height: 150px;*/
  margin: 1px;
  padding: 1px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
</style>