<template>
  <el-row style="margin-bottom: 10px;margin-top: -10px">
    <el-card class="box-card" style="width: 80%;margin-bottom: 5px">
      <el-form ref="form_script" :model="setForm" label-width="80px">
<!--        <el-form-item label="问题话术">-->
<!--          <el-input v-model="setForm.wtMsg" placeholder="#号为分隔符,随机抽取" style="width: 80%"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="验证话术">-->
<!--          <el-input v-model="setForm.yzMsg" placeholder="#号为分隔符,随机抽取" style="width: 80%"></el-input>-->
<!--        </el-form-item>-->
        <el-form-item label="聊天话术">
          <el-input type="textarea" :rows="5" v-model="setForm.chatMsg" placeholder="一行一句话 #号为分隔符,随机抽取。支持变量: %表情% %字符% %图片% %换行%" style="width: 80%"></el-input>
          <el-tag type="info">变量说明:%表情%=随机表情 %字符%=随机英文字符 %图片%=聊天中发送图片(支持多张,随机选取),图片目录: Download/聊天</el-tag>
        </el-form-item>

        <el-form-item label="聊天设置" style="margin-top: -20px">
          <el-checkbox v-model="setForm.isChat_add">加人任务开启聊天</el-checkbox>
          <el-checkbox v-model="setForm.isChat_lh">留痕任务开启聊天</el-checkbox>
          <el-checkbox v-model="setForm.isSend">重发话术</el-checkbox>
          <el-checkbox v-model="setForm.isOld">寻找历史好友</el-checkbox>

        </el-form-item>


        <el-form-item label="精选图片" style="margin-top: -20px ;width: 50%">
          <el-upload
              ref="upload_jx"
              action=""
              list-type="picture-card"
              accept=".jpg,.png"
              multiple
              :auto-upload="false"
              :file-list="fileList_jx"
              :limit="3"
              :http-request="addUploadJX"
              :on-exceed="handleLimit"
              :on-change="handChangeFile_jx"
          >
            <i slot="default" class="el-icon-plus"></i>
            <div slot="file" slot-scope="{file}">
              <img
                  class="el-upload-list__item-thumbnail"
                  :src="file.url" alt=""
              >
              <span class="el-upload-list__item-actions">
              <span
                  class="el-upload-list__item-preview"
                  @click="handlePictureCardPreview(file)"
              >
                <i class="el-icon-zoom-in"></i>
              </span>
              <span
                  v-if="!upload_disabled"
                  class="el-upload-list__item-delete"
                  @click="handleDownload(file)"
              >
                <i class="el-icon-download"></i>
              </span>
              <span
                  v-if="!upload_disabled"
                  class="el-upload-list__item-delete"
                  @click="handleRemove_jx(file)"
              >
                <i class="el-icon-delete"></i>
              </span>
            </span>
            </div>
          </el-upload>

          <el-col>
            <el-button style="margin-left: 10px;" size="small" type="warning" @click="upload_clear('jx')">清除精选图片</el-button>
            <el-button style="margin-left: 10px;" size="small" type="success" @click="uploadPhoto('jx')">上传精选图片</el-button>
            <el-checkbox v-model="setForm.changeJX" style="margin-left: 20px">启动时强制更换精选图片</el-checkbox>
          </el-col>
        </el-form-item>

        <el-form-item label="说说话术">
          <el-input v-model="setForm.kjMsg" placeholder="#号为分隔符,随机抽取。支持变量: %表情% %字符% " style="width: 80%"></el-input>
          <el-tag type="info">变量说明:%表情%=随机表情 %字符%=随机英文字符。 图片目录: Download/说说</el-tag>
        </el-form-item>

        <el-form-item label="说说图片" style="width: 50%">
          <el-upload
              ref="upload_kj"
              action=""
              list-type="picture-card"
              accept=".jpg,.png"
              multiple
              :auto-upload="false"
              :file-list="fileList_kj"
              :limit="3"
              :http-request="addUploadKJ"
              :on-exceed="handleLimit"
              :on-change="handChangeFile_kj"
          >
            <i slot="default" class="el-icon-plus"></i>
            <div slot="file" slot-scope="{file}">
              <img
                  class="el-upload-list__item-thumbnail"
                  :src="file.url" alt=""
              >
              <span class="el-upload-list__item-actions">
              <span
                  class="el-upload-list__item-preview"
                  @click="handlePictureCardPreview(file)"
              >
                <i class="el-icon-zoom-in"></i>
              </span>
              <span
                  v-if="!upload_disabled"
                  class="el-upload-list__item-delete"
                  @click="handleDownload(file)"
              >
                <i class="el-icon-download"></i>
              </span>
              <span
                  v-if="!upload_disabled"
                  class="el-upload-list__item-delete"
                  @click="handleRemove_jx(file)"
              >
                <i class="el-icon-delete"></i>
              </span>
            </span>
            </div>
          </el-upload>

          <el-col >
            <el-button style="margin-left: 10px;" size="small" type="warning" @click="upload_clear('kj')">清除说说图片</el-button>
            <el-button style="margin-left: 10px;" size="small" type="success" @click="uploadPhoto('kj')">上传说说图片</el-button>
            <el-checkbox v-model="setForm.changeKJ" style="margin-left: 20px">启动时强制更换说说</el-checkbox>
          </el-col>
        </el-form-item>


        <el-form-item label="保存模板">
          <el-select v-model="setForm.lab" placeholder="请选择模板" @change="selConfigLab">
            <el-option
                v-for="item in configOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
          <!--          <el-input v-model="setForm.lab" placeholder="新的标识" style="width: 35%; margin-left: 10px" v-show="showConfigLab" ></el-input>-->
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="post_setScript()">保存设置</el-button>
        </el-form-item>
      </el-form>
    </el-card>

    <el-dialog :visible.sync="upload_dialogVisible">
      <img width="100%" :src="upload_dialogImageUrl" alt="">
    </el-dialog>

  </el-row>
</template>

<script>


export default {
  data(){
    return {
      userName : window.sessionStorage.getItem('account'),
      configOptions: [{
        value: '配置1',
      },{
        value: '配置2',
      },{
        value: '配置3',
      },{
        value: '配置4',
      },{
        value: '配置5',
      },],
      configArr:[],
      setForm: {
        wtMsg:'',
        yzMsg:'',
        chatMsg:'',
        kjMsg:'',
        lab: '配置1',
        isSend:false,
        isChat_add:false,
        isChat_lh:false,
        isOld:false,
        changeJX:false,
        changeKJ:false,
        form_jx:null,
        form_kj:null,
      },
      fileList_jx: [],
      fileList_kj: [],
      rules: {
        name: [
          { type: 'Number',required: true, message: '请输入生成数量', trigger: 'blur'},
          {min: 1, max: 3, message: '长度在 1 到 3 个字符', trigger: 'blur'}
        ],
      },

      machineLog:'',
      timeoutId:null,
      upload_dialogImageUrl: '',
      upload_dialogVisible: false,
      upload_disabled: false
    }
  },
  created() {
    this.loadDefault();

  },
  beforeDestroy() {

  },
  methods:{
    loadDefault(){
      this.getDefaultConfig();
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => {
        this.selConfigLab('配置1');
      }, 500);
    },
    uploadPhoto(type) {

      this.$msgBox.confirm('确认上传？现有的图片将被替换','提示',{confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning',})
          .then(async ()=>{
            let formData;
            if (type === "jx"){
              this.setForm.form_jx = new FormData();
              this.setForm.form_jx.append('lab', this.setForm.lab);
              this.setForm.form_jx.append('type', 'jx');
              this.setForm.form_jx.append('account', this.userName);
              this.$refs.upload_jx.submit();
              console.log("form_jx",this.setForm.form_jx);
              formData = this.setForm.form_jx;

            }else if (type === "kj"){
              this.setForm.form_kj = new FormData();
              this.setForm.form_kj.append('lab', this.setForm.lab);
              this.setForm.form_kj.append('type', 'kj');
              this.setForm.form_kj.append('account', this.userName);
              this.$refs.upload_kj.submit();
              console.log("form_kj",this.setForm.form_kj);
              formData = this.setForm.form_kj;
            }

            const {data: res} = await this.$ajax.post("/api/config/upload", formData);
            if (res.code === 200) {
              this.$msgNot.success(res.msg);
              await this.getDefaultConfig();
              clearTimeout(this.timeoutId);
              this.timeoutId = setTimeout(() => {
                this.selConfigLab(this.setForm.lab);
              }, 500);
            } else {
              this.$msgNot.error(res.msg);
            }

          }).catch(()=>{

      });



    },

    upload_clear(type){
      this.$msgBox.confirm('确认清除云端图片？','提示',{confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning',})
          .then( ()=>{
                if (type === "jx"){
                  this.fileList_jx = [];
                }else if (type === "kj"){
                  this.fileList_kj = [];
                }

          }).catch(()=>{

          });
    },
    //添加精选图片
    addUploadJX({ file }) {
      console.log("添加精选图片",file);
      this.setForm.form_jx.append('file[]', file);

    },
    //添加空间图片
    addUploadKJ({ file }) {
      console.log("添加空间图片",file);
      this.setForm.form_kj.append('file[]', file);

    },
    uploadSuccess(res, file, fileList){
      console.log("上传成功:"+file);
    },
    beforeUpload(file) {

      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$msgNot.error('上传文件大小不能超过 2MB!');
      }
      return isLt2M;
    },
    handleLimit(file, fileList) {
      this.$msgNot.error('超出限制,最多3张');
    },
    handChangeFile_jx(file, fileList) {
      this.fileList_jx = fileList;
      console.log("插入精选图",this.jx);
    },
    handChangeFile_kj(file, fileList) {
      this.fileList_kj = fileList;
      console.log("插入空间图",this.kj);
    },

    handleRemove_jx(file, fileList) {
      // console.log(JSON.stringify(file));
      //
      // this.fileList_jx.forEach((item, i) => {
      //   if (item.uid === file.uid) {
      //     this.fileList_jx.splice(i, 1)
      //   }
      // })

    },
    handleRemove_kj(file, fileList) {
      // console.log(JSON.stringify(file));
      //
      // this.fileList_kj.forEach((item, i) => {
      //   if (item.uid === file.uid) {
      //     this.fileList_kj.splice(i, 1)
      //   }
      // })

    },
    handlePreview(file) {
      console.log(file);
    },
    handlePictureCardPreview(file) {
      this.upload_dialogImageUrl = file.url;
      this.upload_dialogVisible = true;
    },
    handleDownload(file) {
      console.log(file);
    },

    //读取默认配置
    selConfigLab(value) {
      console.log(value);
      if (this.configArr){
        let json = JSON.parse(this.configArr);
        for (const k in json) {
          if(value === json[k].lab){
            console.log("读取"+json[k].lab,json[k].config);
            this.setForm   = JSON.parse(json[k].config);
            this.fileList_jx  = this.setForm.jx;
            this.fileList_kj  = this.setForm.kj;
            break
          }
        }
      }
    },

    async post_setScript() {
      if (!this.userName) {
        this.$msgNot.error('登录状态异常,请重新登录');
        return;
      }
      console.log(JSON.stringify(this.setForm));
      if (this.setForm.lab === ""){
        this.$msgNot.error('请选择模板');
        return;
      }

      this.$refs['form_script'].validate(async (valid) => {
        if (valid) {
          const {data: res} = await this.$ajax.post("/api/config/setConfig", {'account': this.userName, 'data':this.setForm});
          if (res.code === 200) {
            this.$msgNot.success(res.msg);
            this.showSetScript = false;
          } else {
            this.$msgNot.error(res.msg);
          }

        } else {
          console.log('error postForm!!');
          return false;
        }
      });

    },
    async getDefaultConfig() {
      if (!this.userName) {
        this.$msgNot.error('登录状态异常,请重新登录');
        return;
      }

      const {data: res} = await this.$ajax.post("/api/config/getConfig", {'account': this.userName});
      if (res.code === 200) {
        // this.$msgNot.success(res.msg);
        this.configArr = res.data;

      } else {
        this.$msgNot.error(res.msg);
      }
    }

  },
}
</script>

<style scoped>
.el-ta {
  /*background-color: #222D32;*/
  color: #000000;
  text-align: left;
  /*line-height: 150px;*/
  margin: 1px;
  padding: 1px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
</style>
