<template>
  <el-row style="margin-bottom: 10px;margin-top: -10px">
    <el-card class="box-card" style="margin-bottom: 5px;width: 600px">

      <el-button type="primary" @click="_showNewkeyDLG">生成卡密</el-button>
      <el-button type="success" @click="refreshData">刷新数据</el-button>
      <el-input
        placeholder="请输入搜索的卡密"
        v-model="pagParams.searchval"
        clearable
        style="height: 30px; width: 220px;margin-left: 10px;margin-right: 10px"
        prefix-icon="el-icon-view">
      </el-input>
      <el-button type="primary" icon="el-icon-search" round size="medium" @click="searchData">搜索</el-button>

    </el-card>
    <el-col style="margin-top: -5px">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="this.pagParams.page"
          :page-sizes="[20, 100, 200, 500]"
          :page-size="this.pagParams.limit"
          :total="this.pagParams.total"
          layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </el-col>
    <el-table
        class="el-ta"
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        @selection-change="handleSelectionChange"
        stripe
        border
        size="mini"
        highlight-current-row
        highlight-selection-row
    >

      <el-table-column
          type="selection"
          width="40px">
      </el-table-column>

      <el-table-column
          prop="sort"
          label="序号"
          width="80px"
          sortable
          show-overflow-tooltip>
        <template slot-scope="scope">{{ scope.$index+1 }}</template>
      </el-table-column>
      <el-table-column
          prop="type"
          label="卡类"
          width="100px"
          sortable
          :filters="[{ text: '月卡', value: 0 }, { text: '周卡', value: 1 }, { text: '日卡', value: 2 }]"
          :filter-method="filterType"
          filter-placement="bottom-end"
      >
        <template slot-scope="scope">
          <div v-if="scope.row.type === 0">{{ '月卡' }}</div>
          <div v-else-if="scope.row.type === 1">{{ '周卡' }}</div>
          <div v-else-if="scope.row.type === 2">{{ '日卡' }}</div>
        </template>
      </el-table-column>

      <el-table-column
          prop="key"
          label="卡密"
          width="150px"
          sortable
          show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
          prop="belong"
          label="绑定账户"
          width="100px"
          show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
          prop="machine"
          label="绑定编号"
          width="100px"
          sortable
          show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
          prop="state"
          label="状态"
          width="100px"
          sortable
          :filters="[{ text: '未使用', value: 0 }, { text: '已使用', value: 1 }]"
          :filter-method="filterStatus"
          filter-placement="bottom-end"
      >
        <template slot-scope="scope">
          <el-tag
              :type="scope.row.state === 1 ? 'danger' : 'success'"
              disable-transitions>{{scope.row.state === 1 ? '已使用' : '未使用'}}</el-tag>
        </template>
      </el-table-column>

      <el-table-column
          prop="create_time"
          label="创建时间"
          width="140px"
          sortable
          show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
          prop="use_time"
          label="使用时间"
          width="140px"
          sortable
          show-overflow-tooltip
          :formatter="formatDate"
      >
      </el-table-column>
      <el-table-column
          prop="lab"
          label="备注"
          width="140px"
          sortable
          show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
          prop="work"
          label="操作">
        <template slot-scope="scope">
          <el-button type="danger" icon="el-icon-edit" size="mini" @click="editData(scope.row)">禁用</el-button>
          <el-button type="warning" icon="el-icon-error" size="mini" @click="delData(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
        :visible.sync="showResult"
        width="50%"
    >
      <el-input type="textarea" :rows="20" v-model="copyResult"></el-input>
      <el-button type="primary" @click="copyData">复 制</el-button>

    </el-dialog>
    <el-dialog
        :visible.sync="showNewKey"
        width="30%"
        title="生成卡密"
    >
      <el-form ref="form" :model="form" label-width="80px">

        <el-form-item prop="addCount" label="卡密数量"  :rules="[
              { required: true, message: '数量不能为空'},
              { type: 'number', message: '只能输入数字'}]">
          <el-input v-model.number="form.addCount" autocomplete="off" style="width:200px" ></el-input>
        </el-form-item>

        <el-form-item prop="type" label="卡密类型">
          <el-select v-model="form.type" placeholder="卡类型" style="width:200px">
            <el-option label="月卡" value=0></el-option>
            <el-option label="周卡" value=1></el-option>
            <el-option label="日卡" value=2></el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="lab" label="卡密备注" >
          <el-input v-model.number="form.lab" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item >
          <el-button type="primary" @click="postForm('form')">生成卡密</el-button>
        </el-form-item>

      </el-form>
    </el-dialog>
  </el-row>
</template>

<script>

export default {
  data(){
    return {
      userName : window.sessionStorage.getItem('account'),
      tableData: [],
      form: {
        addCount:10,type:'0',lab:''
      },
      pagParams:{
        total:20,limit:20,page:1,searchval:''
      },
      showResult: false,
      showNewKey:false,
      copyResult:'',

    }
  },
  created() {
    this.getList();
  },
  methods:{
    _showNewkeyDLG(){
      this.showNewKey = !this.showNewKey;
    },
    filterStatus(value, row) {
      return row.state === value;
    },
    filterType(value, row) {
      return row.type === value;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pagParams.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.pagParams.page = val;
      this.getList();
    },
    refreshData(){
      this.pagParams.page  = 1;
      // this.pagParams.limit = 5;
      // this.pagParams.total = 5;
      this.pagParams.searchval = '';
      this.getList();
    },
    editData(row){
      console.log(row);
    },
    copyData(){
      this.$copyText(this.copyResult).then(() => {
        this.$msgNot.success('复制成功');
      }, () => {
        this.$msgNot.success('复制失败');
      });
    },
    async delData(id){
      if (!this.userName) {
        this.$msgNot.error('登录状态异常,请重新登录');
        return;
      }
      this.$msgBox.confirm('确认删除卡密?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(async () => {
        const {data:res} = await this.$ajax.post("/api/keyList/delKey",{'account':this.userName,'id':id});
        if (res.code === 200){
          this.$msgNot.success('删除成功');
          await this.getList();
        }else {
          this.$msgNot.error('删除失败:'+res.msg);
        }

      }).catch(() => {

      });

    },
    async getList(){
      if (!this.userName) {
        this.$msgNot.error('登录状态异常,请重新登录');
        return;
      }

      const {data:res} = await this.$ajax.post("/api/keyList/getList",{'account':this.userName,'data':this.pagParams});
      if (res.code === 200){
        this.tableData = res.data;
        this.pagParams.total = res.total;
      }else {
        this.$msgNot.error('失败:'+res.msg);
      }

    },
    async postForm(formName) {
      if (!this.userName) {
        this.$msgNot.error('登录状态异常,请重新登录');
        return;
      }

      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const {data: res} = await this.$ajax.post("/api/keyList/addKey", {'account': this.userName, 'data':this.form});
          if (res.code === 200) {
            this.$msgNot.success(res.msg);
            this.showResult = true;
            this.copyResult = res.data.join('\n');
            await this.getList();
            this.showNewKey = false;
          } else {
            this.$msgNot.error(res.msg);
          }

        } else {
          console.log('error postForm!!');
          return false;
        }
      });



    },
    formatDate(row, column) {
      if(!row[column.property]) return '';
      let date = new Date(row[column.property] * 1000)
      const Y = date.getFullYear() + '-'
      const M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-'
      const D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' '
      const h = date.getHours() < 10 ? '0' + date.getHours() + ':' : date.getHours() + ':'
      const m = date.getMinutes() < 10 ? '0' + date.getMinutes() + ':' : date.getMinutes() + ':'
      const s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      return Y + M + D + h + m + s
    },
    searchData(){
      this.pagParams.page = 1;
      this.getList();
    },
  },
}
</script>

<style scoped>
.el-ta {
  /*background-color: #222D32;*/
  color: #000000;
  text-align: left;
  /*line-height: 150px;*/
  margin: 1px;
  padding: 1px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
</style>
