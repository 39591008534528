<template>
  <el-col>正在退出...</el-col>
</template>

<script>
export default {
  name: "logout",
  mounted() {
    this.logout();


  },
  methods:{
    async logout(){



      let account = window.sessionStorage.getItem('account')
      const {data:res} = await this.$ajax.post("/api/admin/logout",{'account':account});
      if (res.code === 200){
        // this.$msg.warning("退出成功");
        await this.$router.push('/login');
      }else {
        this.$msg.error("退出失败");
      }
      window.sessionStorage.clear();
    }
  }
}
</script>

<style scoped>

</style>