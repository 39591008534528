<template>
  <el-row style="margin-top: -10px" >
    <el-col>
      <el-row style="margin-bottom: 10px">
        <el-card class="box-card" style="width: 1100px;">
          <el-button-group >
            <el-button type="primary" icon="el-icon-refresh" round size="medium" @click="refreshData">刷新数据</el-button>
            <el-button type="primary" icon="el-icon-picture-outline-round" round size="medium" @click="queryData">标识用量</el-button>
            <el-button type="success" icon="el-icon-circle-plus" round size="medium" @click="DialogVisible = true">添加数据</el-button>
            <el-button type="success" icon="el-icon-upload" round size="medium" @click="onUpload">导入数据</el-button>
            <el-button type="warning" icon="el-icon-delete" round size="medium">删除选中</el-button>
            <el-button type="danger" icon="el-icon-delete-solid" round size="medium" @click="clearData">清空全部</el-button>
          </el-button-group>
          <el-input
              placeholder="请输入搜索的数据"
              v-model="pagParams.searchval"
              clearable
              style="height: 30px; width: 200px;margin-left: 10px;margin-right: 10px"
              prefix-icon="el-icon-view">
          </el-input>
          <el-button type="primary" icon="el-icon-search" round size="medium" @click="searchData">搜索</el-button>
        </el-card>
      </el-row>
      <el-popover
          placement="right"
          width="80%"
          trigger="manual"
          v-model="visibleLab">
        <el-table :data="labData" :row-class-name="tableRowClassName" v-loading="loadingLab" border>
          <el-table-column width="120" property="id" label="序号"><template slot-scope="scope">{{ scope.$index+1 }}</template></el-table-column>
          <el-table-column width="150" property="lab" label="标识"></el-table-column>
          <el-table-column width="150" property="total" label="总数"><template slot-scope="scope">{{ scope.row.status_0+scope.row.status_1 }}</template></el-table-column>
          <el-table-column width="150" property="status_1" label="已使用"></el-table-column>
          <el-table-column width="150" property="status_0" label="未使用"></el-table-column>
          <el-table-column width="150" prop="work" label="操作">
            <template slot-scope="scope">
              <el-button type="danger" icon="el-icon-error" size="mini" @click="delLab(scope.row.lab)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
<!--        <el-button slot="reference" @click="visibleStatus = !visibleStatus">click 激活</el-button>-->
      </el-popover>
    </el-col>
    <el-col style="margin-top: -5px">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="this.pagParams.page"
          :page-sizes="[15, 100, 200, 500]"
          :page-size="this.pagParams.limit"
          :total="this.pagParams.total"
          layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </el-col>
    <el-table
        class="el-ta"
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        @selection-change="handleSelectionChange"
        stripe
        border
        size="mini"
        highlight-current-row
        highlight-selection-row
        :default-sort = "{prop: 'sort', order: 'ascending'}">

      <el-table-column
          type="selection"
          width="40px">
      </el-table-column>

<!--      <el-table-column-->
<!--          prop="sort"-->
<!--          label="序号"-->
<!--          width="80px"-->
<!--          sortable-->
<!--          show-overflow-tooltip>-->
<!--        <template slot-scope="scope">{{ scope.$index+1 }}</template>-->
<!--      </el-table-column>-->
      <el-table-column
          prop="lab"
          label="标识"
          width="100px"
          sortable
          show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
          prop="data"
          label="数据"
          width="200px"
          sortable
          show-overflow-tooltip
          >
      </el-table-column>
      <el-table-column
          prop="status"
          label="状态"
          width="100px"
          sortable
          :filters="[{ text: '未使用', value: 0 }, { text: '已使用', value: 1 }]"
          :filter-method="filterStatus"
          filter-placement="bottom-end"
          >
        <template slot-scope="scope">
          <el-tag
              :type="scope.row.status === 1 ? 'danger' : 'success'"
              disable-transitions>{{scope.row.status === 1 ? '已使用' : '未使用'}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
          prop="use_time"
          label="使用时间"
          width="150px"
          sortable
          show-overflow-tooltip
          :formatter="formatDate"
          >
      </el-table-column>
      <el-table-column
          prop="work"
          label="操作">
        <template slot-scope="scope">
<!--          <el-button icon="el-icon-edit" size="mini" @click="editData(scope.row)">修改</el-button>-->
          <el-button type="warning" icon="el-icon-error" size="mini" @click="delData(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>


    <el-dialog
        :visible.sync="DialogVisible"
        width="30%"
        >
      <el-form ref="form" :model="formAdd" label-width="80px">
        <el-form-item label="数据">
          <el-input type="textarea" :rows="10" v-model="tmp" style="width: 80%"></el-input>
        </el-form-item>
        <el-form-item label="标识">
          <el-input v-model="formAdd.lab" style="width: 80%"></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-radio v-model="formAdd.status" :label="0" border>未使用</el-radio>
          <el-radio v-model="formAdd.status" :label="1" border>已使用</el-radio>
        </el-form-item>
        <el-form-item>
<!--          <el-button @click="dialogVisible = false">取 消</el-button>-->
          <el-button type="primary" @click="addData">确 定</el-button>
        </el-form-item>
      </el-form>

    </el-dialog>
    <el-dialog
        :visible.sync="DialogVisibleUpload"
        width="40%">
      <el-form :rules="rules" ref="formUpLoad" :model="formUpLoad" label-width="80px">
        <el-form-item label="数据标识">
          <el-input v-model="formUpLoad.lab" style="width: 50%"></el-input>
        </el-form-item>
        <el-form-item label="文件列表">
          <el-upload
              class="upload-demo"
              ref="upload"
              drag
              action="#"
              list-type="text/txt"
              accept="txt"
              :on-change="handleChange"
              :auto-upload="false">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em>,只支持文本文件txt</div>
          </el-upload>
          <el-button size="medium" type="primary" @click="submitUpload">导入</el-button>
          <el-button size="medium" type="info" @click="DialogVisibleUpload = false">取 消</el-button>
        </el-form-item>
        <el-form-item>

        </el-form-item>
      </el-form>
    </el-dialog>

  </el-row>

</template>

<script>


import {trim} from "core-js/internals/string-trim";

export default {

  data() {
    return {
      userName : window.sessionStorage.getItem('account'),
      tableData: [],
      multipleSelection: [],
      DialogVisible:false,
      DialogVisibleUpload:false,
      visibleLab : false,
      pagParams:{
        total:15,limit:15,page:1,searchval:''
      },
      formAdd: {
        lab:'',data:[], status:0,
      },
      formUpLoad: {
        fileName:'', lab:'',
      },
      rules: {
        lab: [
          { required: true, message: '请填写标识', trigger: 'blur' }
        ]
      },
      labData: [{
        id:1,
        lab: '',
        total: 0,
        status_1:0,
        status_0:0
      }],
      file: null,
      tmp:'',
      loadingLab:true
    }
  },
  created() {
    this.getData();
  },
  methods: {
    onUpload(){
      this.DialogVisibleUpload = !this.DialogVisibleUpload;
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => {
        this.$refs.upload.clearFiles();
      }, 500);

    },
    async submitUpload() {
      if (!this.formUpLoad.lab || !this.file){
        this.$msgNot.error('文件/标识不能为空');
        return;
      }

      const loading = this.$loading({
        lock: true,
        text: '正在导入...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      const formData = new FormData();
      formData.append('file', this.file);
      formData.append('lab', this.formUpLoad.lab);
      formData.append('account', this.userName);
      console.log(this.file);
      await this.$ajax.post('/api/dmgt/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        this.$msgNot.success(response.data.msg+': '+this.file.name);
        loading.close();
        this.DialogVisibleUpload = false;
        this.refreshData();
      }).catch(error => {
        this.$msgNot.error('文件上传失败:'+ error);
        loading.close();
      });
    },
    refreshData(){
      this.visibleLab = false;
      this.pagParams.page  = 1;
      // this.pagParams.limit = 5;
      // this.pagParams.total = 5;
      this.pagParams.searchval = '';
      this.getData();
    },

    editData(row){
      console.log(row);
      // this.DialogVisible = true;
      // this.formAdd.lab   = row.lab;
      // this.formAdd.data   = row.data;
      // this.formAdd.status = row.status;

    },
    searchData(){
      this.pagParams.page = 1;
      this.getData();
    },
    addData() {

      if (!this.tmp || !this.formAdd.lab){
        this.$msgNot.error('数据/标识不能为空');
        return;
      }

      this.DialogVisible = false
      this.saveData();

    },
    async getData(){
      if (!this.userName) {
        this.$msgNot.error('登录状态异常,请重新登录');
        return;
      }
      const {data:res} = await this.$ajax.post("/api/dmgt/getData",{'account':this.userName,'data':this.pagParams});
      if (res.code === 200){
        this.tableData = res.data;
        this.pagParams.total = res.total;
      }else {
        this.$msgNot.error('获取数据失败:'+res.msg);
      }

    },
    async queryData(){
      if (!this.userName) {
        this.$msgNot.error('登录状态异常,请重新登录');
        return;
      }
      this.visibleLab = !this.visibleLab;
      if(!this.visibleLab){
          return;
      }

      const {data:res} = await this.$ajax.post("/api/dmgt/queryData",{'account':this.userName});
      if (res.code === 200){
        this.labData = res.data;
        this.loadingLab = false;
      }else {
        this.$msgNot.error('查询用量失败:'+res.msg);
      }

    },
    async saveData(uID = ''){
      if (!this.userName) {
        this.$msgNot.error('登录状态异常,请重新登录');
        return;
      }
      this.formAdd.data = trim(this.tmp).split('\n');
      const {data:res} = await this.$ajax.post("/api/dmgt/saveData",{'account':this.userName,'data':this.formAdd,'uid':uID});
      if (res.code === 200){
        this.$msgNot.success('添加成功,数量:'+ this.formAdd.data.length);
        this.formAdd.data = [];
        this.formAdd.lab = '';
        this.formAdd.status = 0;
        this.tmp = '';
        await this.getData();
      }else {
        this.$msgNot.error('添加数据失败:'+res.msg);
      }

    },
    async delData(id){
      if (!this.userName) {
        this.$msgNot.error('登录状态异常,请重新登录');
        return;
      }

      const {data:res} = await this.$ajax.post("/api/dmgt/deldata",{'account':this.userName,'id':id});
      if (res.code === 200){
        this.$msgNot.success('删除成功');
        await this.getData();
      }else {
        this.$msgNot.error('删除失败:'+res.msg);
      }

    },
    async delLab(lab){
      if (!this.userName) {
        this.$msgNot.error('登录状态异常,请重新登录');
        return;
      }

      const {data:res} = await this.$ajax.post("/api/dmgt/delLab",{'account':this.userName,'lab':lab});
      if (res.code === 200){
        this.$msgNot.success('删除标识成功:'+lab);
        this.visibleLab = true;
        await this.refreshData();
        await this.queryData();
      }else {
        this.$msgNot.error('删除标识失败:'+res.msg);
      }

    },
    async clearData(){
      if (!this.userName) {
        this.$msgNot.error('登录状态异常,请重新登录');
        return;
      }

      await this.$msgBox.confirm('确认清空所有数据？','提示',{confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning',})
          .then(async ()=>{
              const {data:res} = await this.$ajax.post("/api/dmgt/clearData",{'account':this.userName});
              if (res.code === 200){
                this.$msgNot.success('清空成功');
                await this.getData();
              }else {
                this.$msgNot.err('清空失败:'+res.msg);
              }

          }).catch(()=>{

          });
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pagParams.limit = val;
      this.getData();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.pagParams.page = val;
      this.getData();
    },
    filterStatus(value, row) {
      return row.status === value;
    },
    handleChange(file) {
      this.file = file.raw;
    },
    tableRowClassName({row, rowIndex}) {
      return 'warning-row';
    },
    formatDate(row, column) {
      if(!row[column.property]) return '';
      let date = new Date(row[column.property] * 1000)
      const Y = date.getFullYear() + '-'
      const M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-'
      const D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' '
      const h = date.getHours() < 10 ? '0' + date.getHours() + ':' : date.getHours() + ':'
      const m = date.getMinutes() < 10 ? '0' + date.getMinutes() + ':' : date.getMinutes() + ':'
      const s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      return Y + M + D + h + m + s
    }
  }

}
</script>

<style>

.el-ta {
  /*background-color: #222D32;*/
  color: #000000;
  text-align: left;
  /*line-height: 150px;*/
  margin: 1px;
  padding: 1px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.el-table .warning-row {
  background: #f0f9eb;
}


</style>
