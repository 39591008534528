<template>
  <el-row style="margin-bottom: 10px;margin-top: -10px">
    <el-card class="box-card" style="margin-bottom: 5px">

        <span >
            <el-button type="primary" @click="showAddDiablo">增加用户</el-button>
            <el-button type="success" @click="refreshData">刷新数据</el-button>
        </span>
    </el-card>
    <el-col style="margin-top: -5px">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="this.pagParams.page"
          :page-sizes="[20, 100, 200, 500]"
          :page-size="this.pagParams.limit"
          :total="this.pagParams.total"
          layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </el-col>
    <el-table
        class="el-ta"
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        @selection-change="handleSelectionChange"
        stripe
        border
        size="mini"
        highlight-current-row
        highlight-selection-row
    >

      <el-table-column
          type="selection"
          width="40px">
      </el-table-column>
            <el-table-column
                prop="id"
                label="序号"
                width="80px"
                sortable
                show-overflow-tooltip>
<!--              <template slot-scope="scope">{{ scope.$index+1 }}</template>-->
            </el-table-column>
      <el-table-column
          prop="username"
          label="用户名"
          width="120px"
          sortable
          show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
          prop="password"
          label="密码"
          width="100px"
          show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
          prop="status"
          label="状态"
          width="100px"
          sortable
          :filters="[{ text: '冻结', value: 0 }, { text: '正常', value: 1 }]"
          :filter-method="filterStatus"
          filter-placement="bottom-end"
      >
        <template slot-scope="scope">
          <el-tag
              :type="scope.row.status === 0 ? 'danger' : 'success'"
              disable-transitions>{{scope.row.status === 1 ? '正常' : '冻结'}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
          prop="type"
          label="类型"
          width="100px"
          sortable
          show-overflow-tooltip
      >
        <template slot-scope="scope">
          <el-tag
              :type="scope.row.type === 0 ? 'info' : 'info'"
              disable-transitions>{{scope.row.type === 0 ? 'QQ脚本' : 'QQ接码'}}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
          prop="online"
          label="在线"
          width="100px"
          sortable
          show-overflow-tooltip
          :filters="[{ text: '离线', value: 0 }, { text: '在线', value: 1 }]"
          :filter-method="filterOnline"
      >
        <template slot-scope="scope">
          <el-tag
              :type="scope.row.online === 0 ? 'danger' : 'success'"
              disable-transitions>{{scope.row.online === 1 ? '在线' : '离线'}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
          prop="exp_time"
          label="剩余时间"
          width="100px"
          sortable
          show-overflow-tooltip
          :formatter="formatDate"
      >
        <template slot-scope="scope">
          {{scope.row.exp_time ? calculateDays(scope.row.exp_time): ''}}
        </template>

      </el-table-column>
      <el-table-column
          prop="other"
          label="备注"
          width="120px"
          show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
          prop="work"
          label="操作">
        <template slot-scope="scope">
          <el-button icon="el-icon-s-flag" size="mini" @click="showChangeDiablo(scope.row)">更改</el-button>
          <el-button icon="el-icon-edit" size="mini" @click="delData(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
        :visible.sync="showAdd"
        width="30%"
        title="新增用户"
    >
      <el-card class="box-card" style="margin-top: -20px">
        <el-form ref="formAdd" :rules="rules" :model="formAdd" label-width="60px">
          <el-form-item label="用户">
            <el-input v-model="formAdd.username" style="width: 100%;margin-right: 10px;margin-top: -10px"></el-input>
          </el-form-item>
          <el-form-item label="密码">
            <el-input v-model="formAdd.password" style="width: 100%;margin-right: 10px;margin-top: -10px"></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input v-model="formAdd.other" style="width: 100%;margin-right: 10px;margin-top: -10px"></el-input>
          </el-form-item>
          <el-form-item label="类型">
            <el-select v-model="formAdd.type" placeholder="选择类型">
              <el-option label="QQ脚本" value="0"></el-option>
              <el-option label="QQ接码" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="状态">
            <el-radio v-model="formAdd.status" :label="0" border>禁用</el-radio>
            <el-radio v-model="formAdd.status" :label="1" border>正常</el-radio>
          </el-form-item>
          <el-form-item >
            <el-button type="primary" @click="postAddUser">确认增加</el-button>
          </el-form-item>

        </el-form>
      </el-card>

    </el-dialog>
    <el-dialog
        :visible.sync="showChange"
        width="30%"
        title="修改用户"
    >
      <el-card class="box-card" style="margin-top: -20px">
        <el-form ref="formChange" :model="formChange" label-width="60px" >
          <el-form-item label="用户">
            <el-input v-model="formChange.username" disabled style="width: 100%;margin-right: 10px;margin-top: -10px"></el-input>
          </el-form-item>
          <el-form-item label="密码">
            <el-input v-model="formChange.password" style="width: 100%;margin-right: 10px;margin-top: -10px" clearable></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input v-model="formChange.other" style="width: 100%;margin-right: 10px;margin-top: -10px" clearable></el-input>
          </el-form-item>
          <el-form-item label="过期">
<!--            <el-input v-model="formChange.exp_time" style="width: 100%;margin-right: 10px;margin-top: -10px"></el-input>-->
            <el-date-picker
                v-model="curData"
                align="right"
                type="date"
                placeholder="选择日期"
                :picker-options="pickerOptions">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="状态">
            <el-radio v-model="formChange.status" :label="0" border>禁用</el-radio>
            <el-radio v-model="formChange.status" :label="1" border>正常</el-radio>
          </el-form-item>
          <el-form-item >
            <el-button type="primary" @click="postChangeUser">确认修改</el-button>
          </el-form-item>

        </el-form>
      </el-card>

    </el-dialog>

  </el-row>
</template>

<script>

export default {


  data(){
    return {
      userName : window.sessionStorage.getItem('account'),
      tableData: [],
      pagParams:{
        total:20,limit:20,page:1,searchval:''
      },
      formAdd: {
        username:'',
        password:'Aa123456',
        other:'',
        type:'0'
      },
      formChange:{
        username:'',
        password:'',
        exp_time:0,
        status:'',
        other:''
      },
      rules: {
        name: [
          { required: true, message: '字符长度', trigger: 'blur'},
          {min: 3, max: 8, message: '长度在 3 到 8 个字符', trigger: 'blur'}
        ],
        type: [
          { required: true, message: '请选择类型', trigger: 'change'},
        ],
      },

      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
        shortcuts: [{
          text: '+1天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() + 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: '+3天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() + 3600 * 1000 * 24 * 3);
            picker.$emit('pick', date);
          }
        },{
          text: '+7天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() + 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        },{
          text: '+15天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() + 3600 * 1000 * 24 * 15);
            picker.$emit('pick', date);
          }
        },{
          text: '+30天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() + 3600 * 1000 * 24 * 30);
            picker.$emit('pick', date);
          }
        },{
          text: '+180天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() + 3600 * 1000 * 24 * 180);
            picker.$emit('pick', date);
          }
        },{
          text: '+365天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() + 3600 * 1000 * 24 * 365);
            picker.$emit('pick', date);
          }
        }
        ]
      },
      showChange:false,
      showDel:false,
      showAdd:false,
      curData:null
      ,

    }
  },
  created() {
    this.getList();
  },
  methods:{
    filterStatus(value, row) {
      return row.status === value;
    },
    filterOnline(value, row) {
      return row.online === value;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pagParams.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.pagParams.page = val;
      this.getList();
    },
    refreshData(){
      this.pagParams.page  = 1;
      this.pagParams.searchval = '';
      this.getList();
    },
    showAddDiablo(){
      this.showAdd = true;
      this.formAdd.status = 1;
    },
    showChangeDiablo(row){
      this.showChange = true;
      this.formChange = row;
      this.formChange.password = '';
      this.curData = row.exp_time *1000;
    },
    delData(row){
      this.postDelUser(row);
    },
    async getList(){
      if (!this.userName) {
        this.$msgNot.error('登录状态异常,请重新登录');
        return;
      }

      const {data:res} = await this.$ajax.post("/api/admin/getList",{'account':this.userName,'data':this.pagParams});
      if (res.code === 200){
        this.tableData = res.data;
        this.pagParams.total = res.total;

      }else {
        this.$msgNot.error('失败:'+res.msg);
      }

    },
    async postAddUser() {
      if (!this.userName) {
        this.$msgNot.error('登录状态异常,请重新登录');
        return;
      }

      this.$msgBox.confirm('确认增加用户?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info',
        center: true
      }).then(async () => {

        const {data:res} = await this.$ajax.post("/api/admin/addUser",{'account':this.userName,'data':this.formAdd});
        if (res.code === 200){
          this.$msgNot.success(res.msg);
          this.showAdd = false;
          await this.getList();
        }else {
          this.$msgNot.error('操作失败:'+res.msg);
        }

      }).catch(() => {
        this.$msgNot.error('操作异常');
      });
    },
    async postChangeUser() {
      if (!this.userName) {
        this.$msgNot.error('登录状态异常,请重新登录');
        return;
      }

      this.$msgBox.confirm('确认修改用户?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info',
        center: true
      }).then(async () => {
        if (this.formChange.username === ''){
          this.$msgNot.error('用户名不能为空');
          return
        }
        this.formChange.exp_time = parseInt(this.curData/1000);
        const {data:res} = await this.$ajax.post("/api/admin/changeUser",{'account':this.userName,'data':this.formChange});
        if (res.code === 200){
          this.$msgNot.success(res.msg);
          this.showChange = false;

        }else {
          this.$msgNot.error('失败:'+res.msg);
        }

        await this.getList();
      }).catch(() => {
        this.$msgNot.error('操作异常');
      });
    },
    async postDelUser(form) {
      if (!this.userName) {
        this.$msgNot.error('登录状态异常,请重新登录');
        return;
      }

      this.$msgBox.confirm('确认删除用户?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info',
        center: true
      }).then(async () => {

        const {data:res} = await this.$ajax.post("/api/admin/delUser",{'account':this.userName,'data':form});
        if (res.code === 200){
          this.$msgNot.success(res.msg);
          await this.getList();
        }else {
          this.$msgNot.error('操作失败:'+res.msg);
        }

      }).catch(() => {
        this.$msgNot.error('操作异常');
      });
    },
    formatDate(row, column) {
      if(!row[column.property]) return '';

      let date = new Date(row[column.property] * 1000)
      const Y = date.getFullYear() + '-'
      const M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-'
      const D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' '
      const h = date.getHours() < 10 ? '0' + date.getHours() + ':' : date.getHours() + ':'
      const m = date.getMinutes() < 10 ? '0' + date.getMinutes() + ':' : date.getMinutes() + ':'
      const s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      return Y + M + D + h + m + s
    },
    calculateDays(caTime) {
      let t = (caTime - (Date.now() /1000)) / 86400;
      let s = Math.floor(t+1)+'天';
      if (t < 0) s ="过期"
      return s;
    },


  },
}
</script>

<style scoped>
.el-ta {
  /*background-color: #222D32;*/
  color: #000000;
  text-align: left;
  /*line-height: 150px;*/
  margin: 1px;
  padding: 1px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
</style>