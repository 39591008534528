import Vue from 'vue'
import VueRouter from 'vue-router'
import {Message} from "element-ui";
import login from '../views/login'
import home from '../views/home'
import config from '../views/config'
import dmgt from '../views/dmgt'
import myset from '../views/myset'
import logout from '../views/logout'
import admin from "@/views/admin";
import other from "@/views/other";
import keylist from "@/views/keylist";
import userList from "@/views/userList";
import group from "@/views/group";
import phone from "@/views/phone";
import devices from "@/views/devices";

Vue.use(VueRouter)


const routes = [
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/',
    name: 'home',
    component: home,
    children:[
      {
        path: '/devices',
        name: 'devices',
        component: devices
      },
      {
        path: '/config',
        name: 'config',
        component: config
      },
      {
        path: '/dmgt',
        name: 'dmgt',
        component: dmgt
      },
      {
        path: '/group',
        name: 'group',
        component: group
      },
      {
        path: '/myset',
        name: 'myset',
        component: myset
      },
      {
        path: '/other',
        name: 'other',
        component: other
      },
      {
        path: '/phone',
        name: 'phone',
        component: phone
      },
      {
        path: '/admin',
        name: 'admin',
        component: admin
      },
      {
        path: '/keylist',
        name: 'keylist',
        component: keylist
      },
      {
        path: '/userList',
        name: 'userList',
        component: userList
      },
      {
        path: '/logout',
        name: 'logout',
        component: logout
      },
    ]
  },

]

const router = new VueRouter({
  routes
})


let overTime = 2 * 60 * 60 * 1000

router.beforeEach((to,from,next)=>{
  if (to.path === '/login'){
    next();
  }

  //error:Expired token
  let token = window.sessionStorage.getItem('token');
  if (!token || token === '' || token == null || token === undefined){
    next('/login');
  }

  // 验证当前路由所有的匹配中是否需要有登录验证的
  const loginTime = window.sessionStorage.getItem('loginTime');
  if (token && token !== 'undefined' && loginTime) {
    // 携带上登陆成功之后需要跳转的页面完整路径
    let date = new Date().getTime();
    // 当前时间 - 登录时间 > 设置的过期时间，为过期；则清除token，并强制跳转至登录页
    // 反之为有效期，则放行
    if (date - loginTime > overTime) {
      window.sessionStorage.clear();
      Message({
        message: "登录已失效，请重新登录",
        type: 'error',
        duration: 5 * 1000
      })
      next('/login');
    } else {
      next();
    }

  } else {
    next('/login');
  }

  next();

})

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

export default router
